import { useEffect } from "react";

export default function AppRedirectPage() {
  useEffect(() => {
    const IS_IPAD = navigator.userAgent.match(/iPad/i) != null;
    const IS_IPHONE = !IS_IPAD && ((navigator.userAgent.match(/iPhone/i) != null) || (navigator.userAgent.match(/iPod/i) != null));
    const IS_IOS = IS_IPAD || IS_IPHONE;
    const IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i) != null;

    if (IS_IOS) {
      window.location = 'https://itunes.apple.com/app/id1512576218';
    } else if (IS_ANDROID) {
      window.location = 'https://play.google.com/store/apps/details?id=no.foodsteps.app'
    } else {
      window.location = 'https://foodsteps.no'
    }
  }, [])

  return null
}
